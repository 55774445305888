
section.services-section {
    padding: 125px 0;

    img {
        box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    }
    .btn {
        min-width: 290px;
    }
    p {
        font-family: 'Montserrat', sans-serif;
        font-size: 14px;
        letter-spacing: 0.15em;
        color: $primary;
        transition: 0.3s;
    }
    a:hover p {
        color: $secondary;
    }
    
}