section.testimonials-section {
    background-color: #B2BDC7;
    padding: 90px 0;

&.testimonials {
    blockquote {
        margin: 0 0 2rem;

        p {
            font-family: 'ivypresto-text', serif !important;
            @include media-breakpoint-up(xxl) {
                & {
                    font-size: 27px;
                    line-height: 43px;
                }
            }
            @include media-breakpoint-down(xxl) {
                font-size: 27px;
                line-height: 43px;
            }
        }

        a {
            color: #fff;

            &:hover {
                color: #d9d9d9;
            }
        }

        cite {
            :nth-child(2) {
                display: none !important;
            }
            :nth-child(3) {
                display: none !important;
            }
        }
        
        footer {
            cite {
                span {
                    font-family: 'Montserrat', sans-serif;
                    font-size: 18px;
                    font-weight: 700;
                    letter-spacing: 0.15em !important;
                }
                &::before {
                    content: '-';
                    position: relative;
                    margin-right: 5px;
                }

                span {
                    display: inline !important;
                }
            }
        }
    }
    .testimonial-wrapper {
        border-right: none !important;
        @media screen and (max-width: 991px) {
            & {
                margin-bottom: 0px !important;
                margin-top: 0 !important;
            }
        }
        @include media-breakpoint-up(xxl) {
            & {
                padding: 40px 0 30px;
            }
        }
        @media screen and (max-width: 1720px) {
            & {
                padding: 40px 0 30px;
            }

        }
        @include media-breakpoint-down(xl) {
            & {
                padding: 40px 0 30px;
            }
        }
        @include media-breakpoint-down(sm) {
            & {
                padding: 40px 0 30px;
            }
        }

    }
}
    
}