section.hero-section {
    min-height:400px;
    height: 875px;

    .mod {
        min-height:400px;
        height: 875px;

        .logo {
            width: auto;
            margin: 0 auto 30px;
        }

        .display-2 {
            font-family: 'Montserrat', sans-serif;
            font-size: 60px;
            font-weight: 300;
            letter-spacing: 0.06em;
            line-height: 75px;
        }
    }
    @media screen and (max-width: 1600px) {
        & {
            height: 620px;
        }
        .mod {
            height: 620px;
        }
    }
    @media screen and (max-width: 1199px) {
        & {
            height: 63vw;
        }
        .mod {
            height: 63vw;
        }
    }
}